@import './vars';

html, body {
  font-family: 'Nunito', sans-serif;
  font-size: 62.5%;
  padding: 0;
  margin: 0;
  background-color: $blue-600;
}

h1, p {
  margin: 0;
  color: #fff;
}