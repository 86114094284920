@import '../../vars';

/*  === DESKTOP === */
@media (min-width: 769px) {
  section#services {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    padding: 0 65px;
  
    h1 {
      font-size: 3.6rem;
    }
  
    .cards {
      display: flex;
      gap: 65px;
      margin-top: 60px;
  
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $blue-300;
        padding: 50px;
  
        .texts {
          margin-top: 90px;
          display: flex;
          flex-direction: column;
          align-items: center;
  
          h1 {
            font-size: 3.6rem;
          }
  
          p {
            font-size: 2.0rem;
            text-align: center;
          }
        }
  
        img {
          width: 250px;
          height: auto;
        }
      }
    }
  }
}
/*  === END DESKTOP === */

/* === MOBILE === */
@media (max-width: $bkpoint-mobile) {
  section#services {
    margin-top: 25px;
    padding: 0 21px;

    h1 {
      font-size: 2.4rem;
      text-align: center;
    }

    .cards {
      display: flex;
      margin-top: 10px;
      justify-content: center;

      .card {
        padding: 18px;
        height: 290px;
        background-color: $blue-300;
        display: flex;
        flex-direction: column;
        align-items: center;

        .texts {
          h1 {
            margin-top: 30px;
            font-size: 3.2rem;
            text-align: center;
          }

          p {
            font-size: 1.6rem;
            text-align: center;
          }
        }

        &.hidden {
          display: none;
        }
      }
    }

    .swiper-pagination {
      bottom: 0;
      position: absolute;
  
      .swiper-pagination-bullet {
        width: 17px;
        height: 17px;
        background: #fff;
        opacity: 1;
  
        &.swiper-pagination-bullet-active {
          background: $red;
        }
      }
    }
  }
}
/* === END MOBILE === */
