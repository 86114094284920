@import '../../vars';

/*  === DESKTOP === */
@media (min-width: 769px) {
  header {
    height: 95px;
    background-color: $blue-900;
    padding: 0 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    nav {
      ul {
        display: flex;
        align-items: center;
        gap: 42px;
  
        li {
          a {
            font-size: 2.4rem;
            font-weight: 900;
            color: #fff;
            text-decoration: none;
            transition: 0.2s;

            img {
              width: 38px;
              height: 38px;
              border-radius: 100%;
            }
  
            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }
}
/*  === END DESKTOP === */

/* === MOBILE === */
@media (max-width: $bkpoint-mobile) {
  header {
    background-color: $blue-900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;

    a {
      img {
        width: 30px;
      }
    }
    
    img {
      width: 115px;
    }
  }
}
/* === END MOBILE === */