@import '../../vars';

/*  === DESKTOP === */
@media (min-width: 769px) {
  section#projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 125px;
    padding: 0 115px;
  
    h1 {
      font-size: 3.6rem;
    }

    img {
      width: 1000px;

      &:hover {
        cursor: pointer;
      }
    }

    .cards {
      margin-top: 50px;
      max-width: 1000px;

      h1 {
        margin-top: 30px;
        font-size: 2.4rem;
      }

      p {
        margin-top: 10px;
        font-size: 2.0rem;
      }

      .swiper-pagination {
        top: 85%;
    
        .swiper-pagination-bullet {
          width: 17px;
          height: 17px;
          background: #fff;
          opacity: 1;
    
          &.swiper-pagination-bullet-active {
            background: $red;
          }
        }
      }
    }
  }
}
/*  === END DESKTOP === */

/* === MOBILE === */
@media (max-width: $bkpoint-mobile) {
  section#projects {
    margin-top: 21px;
    padding: 0 21px;

    h1 {
      font-size: 2.4rem;
    }

    .card {
      margin-top: 22px;
      width: 100%;

      h1 {
        font-size: 1.6rem;
      }

      p {
        font-size: 1.2rem;
      }

      img {
        margin-top: 12px;
        width: 100%;
        height: 228px;
      }
    }

    .counter-swiper {
      display: flex;
      justify-content: center;
      gap: 8px;
      margin-top: 13px;

      .grey {
        width: 17px;
        height: 17px;
        background-color: #fff;
        border-radius: 50%;

        &.active {
          background-color: $red;
        }
      }
    }

    .swiper-pagination {
      bottom: 0;
      position: static;
  
      .swiper-pagination-bullet {
        width: 17px;
        height: 17px;
        background: #fff;
        opacity: 1;
  
        &.swiper-pagination-bullet-active {
          background: $red;
        }
      }
    }
  }
}
/* === END MOBILE === */
