@import '../../vars';

/*  === DESKTOP === */
@media (min-width: 769px) {
  footer {
    background-color: $gray;
    padding: 28px 100px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: space-between;
  
    h1 {
      font-size: 2.4rem;
    }
  }
}
/*  === END DESKTOP === */

/* === MOBILE === */
@media (max-width: $bkpoint-mobile) {
  footer {
    display: flex;
    padding: 25px 10px;
    align-items: center;
    flex-direction: column;
    background-color: $gray;
    margin-top: 75px;
  }
}
/* === END MOBILE === */